import React, { useState, useEffect, useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function Messages({ threadId, filePath, chatId, initialChatHistory, handleCloseSidebar }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReceivingMessages, setIsReceivingMessages] = useState(false);
  const [codeBlocks, setCodeBlocks] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);
  const websocketRef = useRef(null);
  const inputRef = useRef(null);
  const wsMessagesRef = useRef([]);
  const promptRef = useRef('');
  const chatBoxRef = useRef(null);
  const chatEndRef = useRef(null);

  const base_url = 'https://saas.ravian.ai/v1/api';
  const authkey = localStorage.getItem('token');

  useEffect(() => {
  
    if (initialChatHistory && initialChatHistory.length > 0) {
      const formattedMessages = initialChatHistory.flatMap(chat => [
        {
          text: chat.prompt,
          sender: 'user',
          time: new Date(chat.createdAt).toLocaleTimeString(),
        },
        
        ...chat.response.map(msg => ({
          text: msg.content,
          sender: msg.name,
          time: new Date(chat.createdAt).toLocaleTimeString(),
          isCodeExecutor: msg.name === 'CodeExecutor',
        }))
      ]);
      setMessages(formattedMessages);
      wsMessagesRef.current = formattedMessages;

      const initialCodeBlocks = formattedMessages.flatMap(msg => extractCodeBlocks(msg.text));
      setCodeBlocks(initialCodeBlocks);
    } else {
      setMessages([]);
      wsMessagesRef.current = [];
      setCodeBlocks([]);
    }
  }, [threadId, initialChatHistory]);

  useEffect(() => {
  
    let ws = null;
    if (threadId) {
      ws = new WebSocket(`wss://core.ravian.ai/v1/ws/${threadId}`);
      websocketRef.current = ws;

      ws.onopen = () => {
        console.log('Connected to WebSocket');
        setIsWebSocketReady(true);
        ws.send(`xx_xx ${filePath}`);
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          handleWebSocketMessage(message);
        } catch (e) {
          console.error('Error parsing or processing message:', e);
        } finally {
          setIsLoading(false);
        }
      };

      ws.onclose = () => {
        console.log('WebSocket Disconnected');
        setIsReceivingMessages(false);
        setIsWebSocketReady(false);
      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setIsReceivingMessages(false);
        setIsWebSocketReady(false);
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [threadId, filePath]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleWebSocketMessage = (message) => {
    if (message && typeof message === 'object') {
      if (message.content) {
        let newMessageData;
        if (message.name === 'CodeExecutor') {
          const pngMatches = message.content.match(/(\w+-)*\w+\.png/g);
          if (pngMatches && pngMatches.length > 0) {
            pngMatches.forEach(chartFilename => {
             
              const finalChartUrl = `https://core.ravian.ai/static/${chartFilename}`;
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  text: finalChartUrl,
                  sender: message.name,
                  time: new Date().toLocaleTimeString(),
                  isChart: true,
                },
              ]);
            });
          }
        } else if ( message.name === 'user_proxy') {
          if(!initialChatHistory?.length){
            newMessageData = {
              text: `Hi ${localStorage.getItem('username')} \n\nYour dataset has been loaded successfully. How may I assist you further?`,
              sender: message.name,
              time: new Date().toLocaleTimeString(),
            };
          }else{
            newMessageData = undefined
          }
        } else if (message.name === 'process_completion') {
          let cleanedContent = message.content.replace(/```[\s\S]*?\n([\s\S]*?)```/g, '$1').trim();
          newMessageData = {
            text: cleanedContent,
            sender: message.name,
            time: new Date().toLocaleTimeString(),
            isDataTable: true,
          };
        } else if (message.name !== 'Planner' && message.name !== 'CodeWriter' && message.name !== 'CodeExecutor') {
          newMessageData = {
            text: message.content,
            sender: message.name,
            time: new Date().toLocaleTimeString(),
          };
        }

        if (newMessageData) {
          setMessages((prevMessages) => [...prevMessages, newMessageData]);
          wsMessagesRef.current.push(newMessageData);

          const newCodeBlocks = extractCodeBlocks(newMessageData.text).blocks;
          if (newCodeBlocks.length > 0) {
            setCodeBlocks((prevCodeBlocks) => [...prevCodeBlocks, ...newCodeBlocks]);
          }
        }
      } else if (Array.isArray(message)) {
        message.forEach(handleWebSocketMessage);
      }
    }

    if (!message || (!message.content && !Array.isArray(message))) {
      saveChatContent(promptRef.current);
      setIsReceivingMessages(false);
    }
  };

  const saveChatContent = async (prompt) => {
    try {
      if(!prompt) return
      const filteredResponses = wsMessagesRef.current.filter(
        (msg) => msg.sender !== 'user'
      );

      const response = await axios.post(`${base_url}/chat/create`, {
        threadId,
        prompt: prompt,
        response: filteredResponses.map((msg) => ({
          content: msg.text,
          name: msg.sender,
          role: msg.sender === 'system' ? 'system' : 'user',
        })),
      }, {
        headers: {
          'Content-Type': 'application/json',
          'authkey': authkey,
        }
      });

      if (!response.data.meta.status) {
        throw new Error('Failed to save chat content');
      }
    } catch (error) {
      console.error('Error saving chat content:', error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !isWebSocketReady) return;

    const userMessage = {
      text: newMessage,
      sender: 'user',
      time: new Date().toLocaleTimeString(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    wsMessagesRef.current.push(userMessage);

    promptRef.current = newMessage;

    websocketRef.current.send(newMessage);
    setNewMessage('');
    setIsLoading(true);
    setIsReceivingMessages(true);

    handleCloseSidebar();

    inputRef.current.focus();
  };

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      alert('Code copied to clipboard!');
    });
  };


  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const extractCodeBlocks = (content) => {
    const codeBlockRegex = /```(?:\w*\n)?([\s\S]*?)```/g;
    let blocks = [];
    let cleanedContent = content;

    let match;
    while ((match = codeBlockRegex.exec(content)) !== null) {
      blocks.push({
        language: 'python',  // Default language, can be dynamically assigned if needed
        code: match[1].trim(),
      });
      // Remove the code block from the content
      cleanedContent = cleanedContent.replace(match[0], '').trim();
    }

    return { cleanedContent, blocks };
  };
  //for single code block

  const toggleDrawer = (msg) => {
    const { blocks } = extractCodeBlocks(msg.text);  // Extract blocks from the message text
    setCodeBlocks(blocks);  // Store blocks in state
    setDrawerVisible(true); // Open the drawer
  };

  // for multiple code blocks

  // const toggleDrawer = (msg) => {
  //   const { blocks } = extractCodeBlocks(msg.text);

  //   if (blocks.length > 0) { 
  //     setCodeBlocks(prevBlocks => [...prevBlocks, ...blocks]); // Append to previous blocks
  //   }

  //   setDrawerVisible(!drawerVisible);
  // };
  // In renderMessage, pass the message `msg` to toggleDrawer when clicking the button
  const renderMessage = (msg, index) => {
    if (!msg || typeof msg !== 'object') {
      console.error('Invalid message format:', msg);
      return null;
    }

    const { cleanedContent, blocks } = extractCodeBlocks(msg.text);
    const hasCodeBlock = blocks.length > 0;

    return (
      <div key={index} className={msg.sender === 'user' ? 'your-message' : 'user-message'}>
        {msg.isChart ? (
          <img src={cleanedContent} alt="Chart" />
        ) : msg.isCodeExecutor ? (
          <SyntaxHighlighter language="python" style={okaidia}>
            {cleanedContent}
          </SyntaxHighlighter>
        ) : msg.isDataTable ? (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              table: ({ children }) => (
                <div className="tablebox">
                  <table className="table table-striped table-responsive">{children}</table>
                </div>
              ),
            }}
          >
            {cleanedContent}
          </ReactMarkdown>
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                return inline ? (
                  <code className={className} {...props}>
                    {children}
                  </code>
                ) : (
                  <SyntaxHighlighter language="python" style={okaidia}>
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                );
              },
              table: ({ children }) => (
                <div className="tablebox">
                  <table className="table table-striped table-responsive">{children}</table>
                </div>
              ),
            }}
          >
            {cleanedContent}
          </ReactMarkdown>
        )}
        {hasCodeBlock && msg.sender === 'DataAgent' && (
          <button className="toggle-drawer-button" onClick={() => toggleDrawer(msg)}>
            <img src="/images/code2.png" alt="View Code" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={`chat-box ${drawerVisible ? 'drawer-open' : ''}`}>
      <div className="chats" ref={chatBoxRef}>
        {messages.map((msg, index) => renderMessage(msg, index))}
        <div ref={chatEndRef} />
        <div className={`right-drawer ${drawerVisible ? 'visible' : 'hidden'}`}>
          <div className='drawer-heading'>
            <h2>Code Blocks</h2>
            <button className="close-drawer-button" onClick={closeDrawer}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="code-area">
            {codeBlocks.length > 0 ? (
              codeBlocks.map((block, index) => (
                <div key={index}>
                  <h3>Code Block {index + 1}
                    <button onClick={() => copyToClipboard(block.code)} className="copy-button">
                      Copy Code
                    </button>
                  </h3>
                  <SyntaxHighlighter language={block.language} style={okaidia}>
                    {block.code}
                  </SyntaxHighlighter>
                </div>
              ))
            ) : (
              <p>No code blocks available</p>
            )}
          </div>
        </div>

      </div>

      <div className="message-box">
        <form className="d-flex align-items-center" onSubmit={handleSendMessage}>
          <div className="msg-area">
            <input
              type="text"
              ref={inputRef}
              placeholder={isReceivingMessages ? "Processing..." : isWebSocketReady ? "Type a new message..." : "Connecting..."}
              className="message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={isReceivingMessages || !isWebSocketReady}
            />
          </div>
          <div className="msg-btn">
            <button type="submit" disabled={isReceivingMessages || !isWebSocketReady}>
              <i className={`fa ${isReceivingMessages ? 'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}