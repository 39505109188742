import React, { useState, useEffect } from 'react';
import Messages from './Messages';
import ChatScreenOne from './ChatScreenOne';
import ChatScreenTwo from './ChatScreenTwo';
import UploadExcelCSV from './UploadExcelCSV';

export default function MainChatWindow({ isNewChat, setIsNewChat, selectedThread, isSidebarVisible, updateSidebarThreads, handleCloseSidebar }) {
  const [currentScreen, setCurrentScreen] = useState('chatScreenOne');
  const [newChatData, setNewChatData] = useState(null);

  useEffect(() => {
    if (isNewChat) {
      setCurrentScreen('chatScreenTwo');
      setIsNewChat(false);
      setNewChatData(null);
    } else if (selectedThread) {
      setCurrentScreen('messages');
      setNewChatData(null);
    }
  }, [isNewChat, selectedThread, setIsNewChat]);

  const handleStartClick = () => {
    setCurrentScreen('chatScreenTwo');
  };

  const handleUploadClick = () => {
    setCurrentScreen('uploadExcelCSV');
  };

  const handleContinueClick = (response) => {
    const newThread = {
      _id: response.data._id,
      title: response.data.title,
      file_path: response.file_path,
      chat_id: response.chat_id
    };
    updateSidebarThreads(newThread);
    setNewChatData({
      threadId: response.data._id,
      filePath: response.file_path,
      chatId: response.chat_id,
      chatHistory: []
    });
    setCurrentScreen('messages');
  };

  return (
    <div className={`main-chat-window ${isSidebarVisible ? 'open' : 'hide'}`}>
      <div className="chat-area">
        {currentScreen === 'chatScreenOne' && (
          <ChatScreenOne onStartClick={handleStartClick} />
        )}
        {currentScreen === 'chatScreenTwo' && (
          <ChatScreenTwo onUploadClick={handleUploadClick} />
        )}
        {currentScreen === 'uploadExcelCSV' && (
          <UploadExcelCSV onContinueClick={handleContinueClick} />
        )}
        {currentScreen === 'messages' && (selectedThread) && (
          <Messages
            threadId={selectedThread?.threadId || newChatData?.threadId}
            filePath={newChatData?.filePath || selectedThread?.threadData?.sources[0]?.fileURL}
            chatId={selectedThread?.chatId || newChatData?.chatId}
            initialChatHistory={selectedThread?.chatHistory || newChatData?.chatHistory || []}
            isNewChat={!!newChatData}
            handleCloseSidebar={handleCloseSidebar} // Pass the explicit close function
          />
        )}
      </div>
    </div>
  );
}
